
import Layout from 'hocs/layouts/Layout';
import Navbar from 'components/navigation/Navbar';
import './style_news.css';
import React, { useState } from 'react';
import cast_img from "./cast_img.png";
import apple_img from "./apple_img.png";
import apache_img from "./apache_img.png";
import sirian_hack from "./sirian-hackers.png";
import nist_img from "./nist-news.png";
import google_5mill from "./goo-5mill.png";



function News() {
 
  const [expandir, setExpandir] = useState(false);
  const toggleExpandir = () => {
    setExpandir(!expandir);
  };
  
  
  
  return (
    <Layout>
      <Navbar />
      <div className="noticias-container">
        <h1><strong> News</strong></h1>
        
        
        <div className={`noticia ${expandir ? 'expandida' : ''}`}>          
        <h2>Syrian Hackers Distributing Stealthy C#-Based Silver RAT to Cybercriminals</h2>
          <img src={sirian_hack} alt="Noticia 1" />
          
          <p>Threat actors operating under the name Anonymous Arabic have released a remote access trojan (RAT) called Silver RAT that's equipped to bypass security software and stealthily launch hidden applications.

"The developers operate on multiple hacker forums and social media platforms, showcasing an active and sophisticated presence," cybersecurity firm Cyfirma said in a report published last week.

The actors, assessed to be of Syrian origin and linked to the development of another RAT known as S500 RAT, also run a Telegram channel offering various services such as the distribution of cracked RATs, leaked databases, carding activities, and the sale of Facebook and X (formerly Twitter) bots. </p>

        </div>
        
        
        <div className="noticia">
          <h2>NIST Warns of Security and Privacy Risks from Rapid AI System Deployment</h2>
          <img src={nist_img} alt="Noticia 2" />
           
          <p>The U.S. National Institute of Standards and Technology (NIST) is calling attention to the privacy and security challenges that arise as a result of increased deployment of artificial intelligence (AI) systems in recent years.

"These security and privacy challenges include the potential for adversarial manipulation of training data, adversarial exploitation of model vulnerabilities to adversely affect the performance of the AI system, and even malicious manipulations, modifications or mere interaction with models to exfiltrate sensitive information about people represented in the data, about the model itself, or proprietary enterprise data," NIST said.

As AI systems become integrated into online services at a rapid pace, in part driven by the emergence of generative AI systems like OpenAI ChatGPT and Google Bard, models powering these technologies face a number of threats at various stages of the machine learning operations.</p>
        

        
        </div>
        
        
        <div className="noticia">
          <h2>Google Settles $5 Billion Privacy Lawsuit Over Tracking Users in 'Incognito Mode'</h2>
          <img src={google_5mill} alt="Noticia 3" />
          
<p>Google has agreed to settle a lawsuit filed in June 2020 that alleged that the company misled users by tracking their surfing activity who thought that their internet use remained private when using the "incognito" or "private" mode on web browsers.

The class-action lawsuit sought at least $5 billion in damages. The settlement terms were not disclosed.

The plaintiffs had alleged that Google violated federal wiretap laws and tracked users' activity using Google Analytics to collect information when in private mode.

They said this allowed the company to collect an "unaccountable trove of information" about users who assumed they had taken adequate steps to protect their privacy online.

Google subsequently attempted to get the lawsuit dismissed, pointing out the message it displayed when users turned on Chrome's incognito mode, which informs users that their activity might still be visible to websites you visit, employer or school, or their internet service provider..</p>
        
        
 
        
        
        </div>
      </div>
      
    </Layout>
  );
}

export default News;

