import React from 'react';
import Layout from 'hocs/layouts/Layout';
import Navbar from 'components/navigation/Navbar';
import Footer from 'components/navigation/Footer';
import "./style-contact.css";


function contacts() {
  return (
    <Layout>
      <Navbar />
      <div className="contact-container">
       
        <div className="c_wh">
          <h2>Enterprise Wpp</h2>
          <p></p>
          <img src={new URL('./qr.jpeg', import.meta.url)}alt="QRW"
          className='whats_qr'/>
        </div>
        
        <div className="email">
          <h2>Email</h2>
          <p> </p>
          <img src={new URL('./gmail_qr.png', import.meta.url)}alt="QRW-email"
          className='email_qr'/>
        </div>
      </div>
      
    </Layout>
  );
}

export default contacts;
      
        
          

