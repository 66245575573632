import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import store from "./store"
import { Provider } from "react-redux";
import Error404 from "containers/errors/Error404";
import Home from "containers/pages/Home";
import Contacto from "containers/pages/Contacto"
import Servicios from "containers/pages/Servicios";
import Vip from "containers/pages/Vip";

import Nosotros from "containers/pages/Nosotros";
import Blog from "containers/pages/Blog";
import {} from "containers/pages/Home";
import Register from "containers/pages/Register";
import News from "containers/pages/News";
import Comingsoon from "containers/pages/Comingsoon";

import Hire from "containers/pages/Hire";




/*Aqui la funcion que trae el error: */
function App() {
  return (
    <Provider store={store}>
    
    <Router>
      <Routes>
        {/*ERROR DISPLAY*/}
        {<Route path="*" element={<Error404/>}/>}
      {/*AQUI RUTAS DE PAGES EN containers*/}
  
      <Route path="/" element={<Home/>}/>
      <Route path="/Contacto" element={<Contacto/>}/>
      <Route path="/Register" element={<Register/>}/>
      <Route path="/Servicios" element={<Servicios/>}/>
      <Route path="/Vip" element={<Vip/>}/>
      <Route path="/Nosotros" element={<Nosotros/>}/>
      <Route path="/Blog" element={<Blog/>}/>
      <Route path="/Register" element={<cta-button/>}/>
      <Route path ="/News" element={<News/>} />
      <Route path ="/Hire" element={<Hire/>}/>
      <Route path ="/Comingsoon" element={<Comingsoon/>}/>
      <Route path ="/Nosotros" element={<Contacto/>}/>





      </Routes>
    </Router>
    </Provider>
  );
}

export default App;
