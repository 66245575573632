// ConstruccionComponent.jsx

import React from 'react';
import style_build from "./style_build.css"
import edif_bui from "./edificiosBuild.png";
import Navbar from 'components/navigation/Navbar';
import Footer from 'components/navigation/Footer';


const ConstructionComponent = () => {
  return (
    <div>
        <Navbar/>
    <div className="construction-container">
      <h1 className="construction-text">¡Project on Build! </h1>
      <img
        className="construction-image"
        src={edif_bui} // Reemplaza con la URL de tu imagen de construcción
        alt="Under Construction"
      />
    </div>
    <Footer/>
    
    </div>
  );
};

export default ConstructionComponent;
