import React from 'react';
import Layout from 'hocs/layouts/Layout';
import Navbar from 'components/navigation/Navbar';
import "./style_hire.css";


const redirectToUrl = () => {
  // Cambia 'URL_DESTINO' con la URL a la que deseas redirigir
  window.location.href = 'https://www.instagram.com/sec.hat_/';
}; 

function Hire() {
  return (
    <Layout>
      <Navbar />
      <div className="hireus-container">
        <h1 className='tittle-hire'  >Services</h1>
        <div className="hire1">
          <h2 style={{fontFamily:"serif"}}>Web Analisys</h2>
          
          <img src={new URL('./websys_img.png', import.meta.url)}
          alt="pic1_h"
          className="pic1_h" //para definir como QRW y que se immporte la edicion de css desde .qrw {} c:
          />
          
        <p>"Web cybersecurity analysis is a comprehensive process that involves identifying and assessing vulnerabilities in your website, applications, and related systems. Its aim is to prevent cyber threats, safeguard sensitive data, and ensure the integrity of the online platform. This process includes risk identification, penetration testing, security audits, and the implementation of measures to strengthen defense against cyber attacks."</p>
          
        </div>
        <div className="hire2">
          <h2 style={{fontFamily:"serif"}}>PenTest</h2>
          
          <img src={new URL('./ptest_imgfav.png', import.meta.url)}
          alt="pic2_h"
          className="pic2_h" //para definir como QRW y que se immporte la edicion de css desde .qrw {} c:

          />
          <p>"Penetration testing, commonly known as pentest, is a security evaluation process conducted to simulate cyber attacks on systems, networks, and applications. The goal is to identify and analyze potential vulnerabilities in a controlled environment, allowing organizations to strengthen their defenses before cybercriminals can exploit these weaknesses. Pentesting involves a series of security tests and techniques, and its findings help enhance cybersecurity and protect an entity's critical assets."</p>
               
        </div>
        <div className="hire3">
          <h2 style={{fontFamily:"serif"}}>Custom Services</h2>
          
          <img src={new URL('./Cust_moreS_img.png', import.meta.url)}
          alt="pic3_h"
          className="pic3_h" //para definir como QRW y que se immporte la edicion de css desde .qrw {} c:
          />
          
          
          
  

<p style={{ fontFamily: "monospace" }}>
  <strong>"Click on Contact for more Information."</strong>
</p>
        

      </div>
      <div className="final-button" onClick={redirectToUrl}>
        CONTACT
      </div>
      
      </div>
    </Layout>
 

 
 
 
 );
}

export default Hire;
