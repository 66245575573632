import Layout from "hocs/layouts/Layout";
import Navbar from "components/navigation/Navbar";
import "./style_healt.css";
import img_height from "./1seat-height.png"
import img_sdepth from "./img_sdepth.png"
import reposabrasos_img from "./reposabrasos.png"
import respaldo_img from "./respaldo_img.png"
import screen_img from "./screen_img.png"
import mouse_img from "./mouse_img.png"
import keyboard_img from "./keyboard_img.png"
import chair_img from "./chair_img.png"
import desk_img from "./desk_img.png"
import high_img from "./high_img.png"
import bicep_img from "./bicep_img.png"



function Blog() {
  return (
    <Layout>
      <Navbar />
      <div className="blog-container">
        <header className="blog-header">
          <div className="blog-title">
            <h1>DOES PAIN BOTHER YOU WHILE WORKING?</h1>
          </div>
          
          <div className="parrafito" >
          <p className="p" >
          It is important to note that prolonged and excessive sitting in front of a computer can result in fatigue and the adoption of dysfunctional postures by the muscles in the back area. These alterations, combined with improper and repetitive movements, lead to and exacerbate discomfort at the cervical, dorsal, or lumbar levels, thus adversely impacting the quality of life. It is worth noting that using the computer for an extended number of hours daily can give rise to visual disturbances, psychological issues, and musculoskeletal disorders, among which the most common include: Neck pain,
Cervicobracuialgia, 
Rotator cuff syndrome,
Carpal tunnel syndrome,
Low back pain.

          </p>
          </div>


        </header>
        <section className="blog-content">
        <div className="blog-section">
  <div className="blog-subtitle">
    <h2>Correct alignment of body parts with the workstation.</h2>
  </div>


<div>
  <p >
  These pathologies can be prevented, readapted, and rehabilitated through physical therapy treatment, as the primary objective is to restore the functionality of the human body.  


Firstly, a workplace should be ergonomic, meaning that the 
space where we work should have equipment (computers, desks, chairs, and space) 
that adapts to the physical characteristics of the worker. This allows for proper 
and functional use, thereby preventing the occurrence of injuries. The ROSA method 
(Rapid Office Strain Assessment) assesses risks associated with elements in workplaces 
such as the work surface, screen, keyboard, mouse, chair, and other peripherals. 
The reputable website 'Ergonautas' provides images illustrating the correct alignment of body parts 
with the workstation.

  </p>

  </div>


  <div className="image-grid">
    <div className="image-item">
      <h2 className="t-1">SEAT HEIGHT</h2>
      <img src={img_height} alt="Estiramiento 2"className="img-post"
       />
      <div className="image-description">Knees bent approximately 90°.</div>
    </div>

    <div className="image-item">
      <h2 className="t-1">SEAT DEPTH</h2>
      <img src={img_sdepth} alt="Estiramiento 2" className="img-post"/>
      <div className="image-description">Approximately 8cm of space between the seat and the back of the knees. </div>
    </div>

  

    <div className="image-item">
      <h2 className="t-1">ARMREST</h2>
      <img src={reposabrasos_img} alt="Estiramiento 2" className="img-post"/>
      <div className="image-description">Elbows well supported in line with the shoulders shoulders should be relaxed. </div>
    </div>

    <div className="image-item">
      <h2 className="t-1">BACKREST</h2>
      <img src={respaldo_img} alt="Estiramiento 2" className="img-post"/>
      <div className="image-description">Backrest reclined between 95° - 110° and adequate lumbar support. </div>
    </div>

    <div className="image-item">
      <h2 className="t-1">SCREEN</h2>
      <img src={screen_img} alt="Estiramiento 2"className="img-post" />
      <div className="image-description">Distance between 45 and 75 cm from the eyes and upper edge at eye level. </div>
    </div>


    <div className="image-item">
      <h2 className="t-1">MOUSE</h2>
      <img src={mouse_img} alt="Estiramiento 2" className="img-post" />
      <div className="image-description">The mouse should be aligned with the shoulder. </div>
    </div>

    <div className="image-item">
      <h2 className="t-1">KEYBOARD</h2>
      <img src={keyboard_img} alt="Estiramiento 2" className="img-post"/>
      <div className="image-description">The wrists are straight and the shoulders are relaxed. </div>
    </div>
   









    {/* Agrega más imágenes y descripciones según sea necesario */}
  </div>
</div>

          <div className="active-b">
            <div className="blog-subtitle">
              <h2>Active Breaks</h2>
            </div>
            <p>
            On the other hand, incorporating 'active breaks,' which involve periods of rest through physical activity in the workplace, is a crucial element in preventing muscular fatigue, especially in the lumbar spine, when sitting for prolonged periods. Taking breaks, changing positions, and engaging in physical activities such as stretching or resistance exercises reduce the strain on anatomical structures caused by prolonged static positions.
Below, you will find stretching guides for various body segments:

            </p>
          
    <div className="image-item">
      <img src={chair_img} alt="Estiramiento 2" />
      <div className="image-description"></div>
    </div>
          
    <div className="image-item">
      <img src={desk_img} alt="Estiramiento 2" />
      <div className="image-description"></div>
    </div>


     <div className="image-item">
      <img src={high_img} alt="Estiramiento 2" />
      <div className="image-description"></div>
    </div>

    <div className="image-item">
      <img src={bicep_img} alt="Estiramiento 2" />
      <div className="image-description"></div>
    </div>      
          
          
          
          
          </div>


          <div className="parrafo">
            <div className="parrafo-final">
    
            </div>
            <p>
            It is important to emphasize the significance of conducting an individualized physiotherapeutic assessment to address respective musculoskeletal conditions directly and effectively based on the unique characteristics of each individual.



            </p>
            
            </div>

          
        </section>
            
        
            <div className="targeta-final">
            <div className="targeta-final">
    
            <h4 className="mi-titl" >Blog Editor :</h4>
            <h2>
            <a href="https://www.instagram.com/angisitaw/" className="name">
            Ángela Medina.
           </a>
          </h2>

            </div>
           
            <h3 className="ths" ><strong>Physiotherapist.</strong></h3>
            
            <h3 className="ths" ><strong>Personal Advice:</strong></h3>
            <h3 className="numberad"  >+593-0962528940, EC. </h3>
            </div>


      </div>
      
    </Layout>
  );
}

export default Blog;
