import {connect} from "react-redux"
import React from "react";
import Navbar from "components/navigation/Navbar";
import Footer from "components/navigation/Footer";

function Layout({ children }) {
  return (
    <div>
      {/* Navbar Section */}
      <header>
        <nav>
          {/* Navbar content goes here */}
          <Navbar />
        </nav>
      </header>

      {/* Content from children */}
      {children}

      {/* Footer Section */}
      <footer>
        
        {/* Footer content goes here */}
        <Footer />
      </footer>
    </div>
  );
}

export default Layout;
 
