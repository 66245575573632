function Vip() {
    return (
    
        <div>
           Vip
        </div>
      
    );
  }
  
  export default Vip;