import Layout from "hocs/layouts/Layout"
import "styles/estilos.css"
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import img_hire_h from "./for_hire.png";
import img_map_h from "./map.png";
import React, { useEffect } from 'react';
import healt_fisio from "./healt.png";
import softhat_img from "./hat_menu.png";
import hat2 from "./sechat-hat2.png";




function TuComponente() {
  const navigate = useNavigate();

  useEffect(() => {
    // Obtén la ruta actual
    const currentPath = window.location.pathname;

    // Cambia el título según la ruta
    if (currentPath === '/Home.jsx') {
      document.title = 'Home | Sec-Hat';
    } 
  }, []);
 
}
function Home() {
  const Register= useNavigate();

  


// Función para manejar el clic en el botón de registro
const handleRegisterClick = () => {
  Register("/Register");
 
}

 
const redirectToUrl = () => {
  // Cambia 'URL_DESTINO' con la URL a la que deseas redirigir
  window.location.href = 'https://www.instagram.com/sec.hat_/';
}; 



return (
   
    

    
    <Layout>
      {/* Hero Section 
      <section className="hat2">
    <div>
    <div className="hat2">
        <img
          className="construction-image"
          src={hat2}
          alt="Under Construction"
        />
      </div>

    </div>

      </section>
  */     } 
      <section className="hero">
      
        
        <div className="hero-content">
          <h1 className="tittle-n"  ><strong>Sec-Hat</strong></h1>
          <h2 className="sub-tH"   ><strong>Cybersecurity Analisys</strong></h2>
          
         
        
        </div>
      </section>
      



{/* CTA button for registration */}
      
{/*<button className="cta-button" onClick={()=>Register("/Register")}>
            Register
</button>*/}



     
   
     
     
     
      {/* Services Section */}
<section className="services">
  <div className="services-content">
    
  
  
  
  
  
  
  <Link to="/Hire" className="service-button">
    <div className="service">
      <div className="service-image">
        <img src= {img_hire_h} alt="service-analisys" 
        className="service-analisys"
        />
      </div>
      <div className="service-info">
        <h3 className="service-name">Cybersecurity Analisys Services </h3>
        <p className="service-description">Hire us.</p>
      </div>
    </div>
    </Link>
   
   
  
   <Link to= "/News" className="service-button">
    <div className="service">
      <div className="service-image">
        <img src={img_map_h} alt="service1" />
      </div>
      <div className="service-info">
        <h3 className="service-name">Cybersecurity News</h3>
        <p className="service-description">News of cyersecurity events. </p>
      </div>
    </div>
    
   </Link>

   <Link to= "/Comingsoon" className="service-button">
    <div className="service">
      <div className="service-image">
        <img src={softhat_img} alt="service1" />
      </div>
      <div className="service-info">
        <h3 className="service-name">SOFTHAT</h3>
        <p className="service-description">Analize your Ip Web and Web Domain for free. "COMING SOON" </p>
      </div>
    </div>
    
   </Link>   

   
   
   
   <Link to= "/Blog" className="service-button">
    <div className="service">
      <div className="service-image">
        <img src={healt_fisio} alt="service1" />
      </div>
      <div className="service-info">
        <h3 className="service-name">Healt & Work</h3>
        <p className="service-description">Better life for your Back - Healt & lifestyle </p>
      </div>
    </div>
    
   </Link>
   
    

    
  </div>
</section>

<div className="final-button" onClick={redirectToUrl}>
        CONTACT
      </div>
      
    </Layout>
    
  );
}

export default Home;

      
        
          

