import React, { useState, useEffect } from 'react';
import './style_about.css';
import Layout from 'hocs/layouts/Layout';
import Navbar from 'components/navigation/Navbar';
import Footer from 'components/navigation/Footer';
import about_img from './about_img.png';
import brain_img from './brain_img.png';

const About = () => {
  const descriptions = [
    ["We are a company dedicated to the performance of cybersecurity analysis and penetration testing.", "from Cuenca,Ecuador"],
    ['Contact us -->>', "ja.vh.dev@gmail.com or (+593)0998476038."],
  ];

  const [descriptionIndex, setDescriptionIndex] = useState(0);
  const [descriptionPart, setDescriptionPart] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDescriptionPart((prevPart) => (prevPart === 0 ? 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Layout>
      <Navbar />
      <div className="about-page">
        <title>ABOUT US </title>
        
        <section className="about-section">
          <div className="about-image">
            <img src={about_img} alt="about_img" />
          </div>
          <div className={`about-description ${descriptionPart === 0 ? 'fade-out' : 'fade-in'}`}>
            <h2 className="title-animation">Us</h2>
            <p  className="parr-us"  >{descriptions[descriptionIndex][descriptionPart]} </p>
          </div>
        </section>
        <section className="cases-section">
          <div className={`about-description ${descriptionPart === 0 ? 'fade-out' : 'fade-in'}`}>
            <h2 className="title-animation">Cases</h2>
            <p className='parr-about'>{descriptions[1 - descriptionIndex][descriptionPart]}
</p>
          </div>
          <div className="about-image">
            <img src={brain_img} alt="Imagen 2" />
          </div>
        </section>
      </div>
     
    </Layout>
  );
};

export default About;

