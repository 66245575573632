import React, { useState } from 'react';
import axios from 'axios';
import Navbar from 'components/navigation/Navbar';
import Footer from 'components/navigation/Footer';
import './Register.css';

function Register() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
  const response = await axios.post('http://localhost:8080', formData, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  console.log(response.data);  // Muestra la respuesta en la consola
} catch (error) {
  console.error('Error en la solicitud:', error);
}
  }

  return (
    <div className="register-container">
      <Navbar />
      <div className="register-card">
        <h1>REGISTER</h1>
        <form onSubmit={handleRegister}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone/Cell:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Your phone number"
              value={formData.phone}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="address">Country:</label>
            <input
              type="text"
              id="address"
              name="address"
              placeholder="Your country"
              value={formData.address}
              onChange={handleInputChange}
            />
          </div>
          <button type="submit" className="R_But">
            Register Me!
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
}

export default Register;
