import {connect} from 'react-redux'
import hat_img from "./hat_img.png";
import "./footer.css";

const navigation = {
    solutions: [
      { name: '', href: '#' },
      { name: '', href: '#' },
      { name: '', href: '#' },
      { name: '', href: '#' },
    ],
    support: [
        { name: '', href: '' },
    ],
    company: [
        { name: '', href: '/casos' },
        { name: '', href: '/servicios' },        
        { name: '', href: '/carreras' },
        { name: '', href: '/blog' },
        {name: "", href: "#"},
        { name: '', href: '' },
    ],
    legal: [
      { name: '', href: '#' },
      { name: '', href: '#' },
      { name: '', href: '#' },
      
    
    ],
    social: [
     
      
      
      {
        name: 'GitHub',
        href: 'https://github.com/Albertt-V',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
              clipRule="evenodd" 
              className='git_icon'
              
            />
          </svg>
        ),
      },
      
    ],
  }

function Footer(){
    return(
        <footer className="bg-gray" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-full py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
  <div className="xl:grid xl:grid-cols-3 xl:gap-8">
    <div className="flex items-center xl:col-span-1">
    <div className="flex flex-col items-center"> 
      <div className="mx-auto"> {/* Agregado */}
        <img
          src={hat_img}
          width={230}
          height={200}
          alt="Hat Image"
          className="rounded-full overflow-hidden"
        />
      </div>
      <div className="flex space-x-6">
        {navigation.social.map((item) => (
          <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
            <span className="sr-only">{item.name}</span>
            <item.icon className="h-6 w-6" aria-hidden="true" />
          </a>
        ))}
      </div>
    </div>
  
  </div>
            <div className="mt-12 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-base font-medium text-gray-900"></h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.solutions.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-base font-medium text-white"></h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.support.map((item) => (
                      <li key={item.name}>
                        <a href={"./Contacto"} className="text-base text-gray-500 hover:text-gray-900">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8 mr:right-100">
                <div>
                  <h3 className="text-base font-medium text-white "></h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.company.map((item) => (
                      <li key={item.name}>
                        <a href={"./Nosotros"} className="text-base text-gray-500 hover:text-gray-900">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>*/
                <div className="mt-12 md:mt-0">
                  <h3 className="text-base font-medium text-white"></h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.legal.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 border-t border-black pt-8">
            <p className="text-base text-white xl:text-center">&copy;Sec-Hat</p>
          </div>
        </div>
      </footer>
    )
}

const mapStateToProps=state=>({

})

export default connect(mapStateToProps, {

}) (Footer)