// Navbar.js

import React from 'react';
import { Link } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';
import hatImg from './hat_img.png';
import panelImg from './hat_menu.png';
import locImg from './loc_img.png';
import './style_navb.css';

const Navbar = () => {
  const solutions = [
    {
      name: 'Contact Us',
      description: 'For more details',
      href: '/Contacto',
    },
    {
      name: 'About Us',
      description: 'Who we are?',
      href: '/nosotros',
    },
  ];

  const buttonStyle = {
    backgroundColor: '#000',
    color: '#fff',
    border: '1px solid #ededed',
    padding: '1rem',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'scale(1)',
    transition: 'transform 0.3s, background 0.3s, box-shadow 0.3s',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    position: 'relative',
  };

  const imgAnimation = {
    animation: 'bounce 0.5s infinite alternate',
  };

  return (
    <nav className="w-full py-6 top-0 transition duration-300 ease-in-out z-40 fixed" style={{ backgroundColor: '#000', color: '#fff' }}>
      <div className="px-4 sm:px-6">
        <div className="-ml-4 -mt-2 hidden lg:flex flex-wrap items-center justify-between sm:flex-nowrap md:px-14 px-2">
          <Link to="/" className="ml-4 mt-2">
            <img src={hatImg} width={90} height={90} className="rounded-circle" alt="Logo" />
          </Link>
          <div className="ml-4 mt-2 flex-shrink-0">
            <Link to="/Contacto" className="inline-flex ml-12 items-center rounded-md border border-blue bg-white-button px-9 py-3 text-base font-medium text-white shadow-sm hover:bg-blue transition duration-300 ease-in-out focus:outline-white focus:ring-2 focus:ring-white-500 focus:ring-offset-2">
              Contact
            </Link>
          </div>
        </div>

        <div className="-ml-4 -mt-2 lg:hidden flex flex-wrap items-center justify-between sm:flex-nowrap md:px-14 px-2">
          <Link to="/" className="ml-4 mt-2">
            <img src={hatImg} width={160} height={160} alt="Logo" />
          </Link>
          <div className="ml-4 mt-2 flex-shrink-0">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button style={buttonStyle}>
                    <i className="bx bx-menu text-4xl"></i>
                    <div style={imgAnimation}>
                      <img src={panelImg} alt="Panel" className="animate-image" style={{ width: '60px', height: '60px', borderRadius: '80px' }} />
                    </div>
                  </Popover.Button>

                  <Transition
                    as={React.Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute -left-32 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black-ring-opacity-5">
                        <div className="relative grid gap-8 bg-black p-7 lg:grid-cols-2">
                          {solutions.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-50"
                            >
                              <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                                {item.icon && <item.icon aria-hidden="true" />}
                              </div>
                              <div className="ml-4">
                                <p className="text-sm font-medium text-blue-500">{item.name}</p>
                                <p className="text-sm text-gray-500">{item.description}</p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
