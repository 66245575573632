import React from 'react';
import ReactDOM from 'react-dom/client';
import { createStore, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from "redux/reducers/reducers"
import './styles/index.css';
import App from './App';
import thunk from 'redux-thunk';
import { applyMiddleware } from 'redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

